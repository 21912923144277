import React, { FC } from 'react'
import {
  Description,
  Image,
  Notice,
  Title,
} from 'components/page/notice/Notice.styled'
import { FlexGrow } from 'components/layout/Layout.styled'
import { FormSubmitGray } from 'components/page/announceSettingsForm/AnnounceSettingsForm.styled'
import { FormattedMessage } from 'react-intl'
import { Indent } from 'constants/sizes'
import { LayoutFull } from 'components/layout/LayoutFull'
import { NotFoundSvg } from 'components/page/notice/icons/NotFoundSvg'
import { Paths } from 'components/components.paths'
import { PrimaryClickButton } from 'components/presentational/announce/buttons/PrimaryButton'
import { Spacer } from 'components/presentational/spacer'
import { WithStaticContext } from 'components/components.types'
import { push } from 'actions/system/router'
import { useAppDispatch } from 'hooks/useAppDispatch'

const NotFound: FC<WithStaticContext> = ({ staticContext }) => {
  const dispatch = useAppDispatch()

  if (staticContext) {
    staticContext.statusCode = 404
  }

  const handleClick = () => {
    dispatch(push(Paths.index))
  }

  return (
    <LayoutFull>
      <Notice>
        <FlexGrow />
        <Spacer height={Indent.indent20} />
        <Image>
          <NotFoundSvg />
        </Image>
        <Spacer height={Indent.indent32} />
        <Title data-name="title-notice-error">
          <FormattedMessage
            id="not.found.title"
            defaultMessage="Упс… Вы попали куда-то не туда"
          />
        </Title>
        <Spacer height={Indent.indent20} />
        <Description data-name="description-notice-error">
          <FormattedMessage
            id="not.found.title.description"
            defaultMessage="Проверьте адрес ссылки или перейдите на главную страницу «Анонимных знакомств»."
          />
        </Description>
        <Spacer height={Indent.indent20} />
        <FlexGrow />
        <FormSubmitGray>
          <PrimaryClickButton
            onClick={handleClick}
            data-name="reload-go-back-action"
          >
            <FormattedMessage id="ok" defaultMessage="Понятно" />
          </PrimaryClickButton>
        </FormSubmitGray>
      </Notice>
    </LayoutFull>
  )
}

export default NotFound
