import React, { FC, PropsWithChildren } from 'react'
import { Indent, SizeViewport } from 'constants/sizes'
import { LinkHeaderForm } from 'components/presentational/announce/linkHeaderForm/LinkHeaderForm'
import { Logo } from 'components/presentational/logo/Logo'
import { LogoLink } from 'components/presentational/logo/Logo.styled'
import { styled } from 'linaria/react'

export const headerInnerCss = `
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  padding: 0 ${Indent.indent20}px;
`

export const Bar = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 92px;
  position: sticky;
  top: 0;
  z-index: 1;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    height: 64px;
  }
`

export const InnerHideLogoMobile = styled.div`
  ${headerInnerCss};

  @media screen and (max-width: ${SizeViewport.phone}px) {
    ${LogoLink} {
      display: none;
    }
  }
`

interface HeaderProps {}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ children }) => {
  return (
    <Bar>
      <InnerHideLogoMobile>
        <Logo />
        {children || <LinkHeaderForm />}
      </InnerHideLogoMobile>
    </Bar>
  )
}
