import { ColorsTheme } from 'constants/announces/ColorTheme'
import { FontSize, Indent, SizeViewport } from 'constants/sizes'
import { circeRegularFamily } from 'constants/fontsFamily'
import { styled } from 'linaria/react'

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const TitleForm = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${FontSize.bigTitle}px;
  color: ${ColorsTheme.primaryText};
  font-family: ${circeRegularFamily}, Arial, serif;
`

export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
`

export const formSubmitCss = `
  
  position: sticky;  
  
  bottom: 0;
  margin-top: ${Indent.indent20}px;  
  
  &::before {
    display: none;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    bottom: calc(100% - 10px);
    width: 100%;   
  }
  
  @media (orientation: landscape) {
    position: static;
  }
  
  @media screen and (max-width: ${SizeViewport.phone}px) {
    & {      
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: ${Indent.indent12}px -${Indent.indent20}px 0 -${Indent.indent20}px;
      padding: 10px ${Indent.indent20}px;
    }

    &:before {
      display: block;
    }
  }
`

export const formSubmitWhiteCss = `
  background-color: white;
  ${formSubmitCss};

  &:before {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 70%
    );
  }
`

export const FormSubmitWhite = styled.div`
  ${formSubmitWhiteCss}
`

export const FormSubmitTwoButtonsWhite = styled.div`
  ${formSubmitWhiteCss};
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  margin-top: 0;

  @media screen and (max-width: ${SizeViewport.phone}px) {
    & {
      margin: 0 -${Indent.indent20}px;
      row-gap: ${Indent.indent20 / 2}px;
    }
  }
`

export const FormSubmitGray = styled.div`
  background-color: ${ColorsTheme.background};
  ${formSubmitCss};

  &:before {
    background: linear-gradient(
      180deg,
      rgba(239, 241, 244, 0) 0%,
      rgba(239, 241, 244, 1) 70%
    );
  }
`
